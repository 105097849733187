<template>
    <div class="reading pb-4">
        <h4 class="date">
            {{ monthConverter.integerToString(reading.date.month) }}
            {{ reading.date.year }}
        </h4>
        <div class="content bg-white p-2">
            <div class="row p-0 m-0 d-flex read py-2 align-items-center">
                <div class="col-6">Read invoices</div>
                <div class="effectif col-6">{{ reading.read }}</div>
            </div>
            <div class="row p-0 m-0 d-flex read py-2 align-items-center">
                <div class="col-6">Valid invoices</div>
                <div class="effectif col-6 valid">{{ reading.valid }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import monthConverter from "@/helpers/monthConverter";

export default {
    props: {
        reading: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            monthConverter,
        };
    },
};
</script>
<style lang="scss" scoped>
@import "../styles/scss/variables";

.reading {
    .date {
        font-weight: bold;
        font-size: 1rem;
    }
    .content {
        border-radius: 0.5rem;
        font-size: 1.4rem;
        .effectif {
            font-size: 2rem;
            font-weight: 600;
        }
        .valid {
            color: $valid-invoice;
        }
    }
}
</style>