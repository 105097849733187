<template>
  <b-overlay :show="showOverlay" class="overlay" opacity="0.8">
    <template #overlay>
      <div class="text-center">
        <b-spinner></b-spinner>
        <p class="py-2">Logging out ...</p>
      </div>
    </template>
    <div class="profile p-3">
      <div class="title">
        Profile
      </div>
      <div class="personal-details text-center">
        <svg-icon class="avatar" icon="user"></svg-icon>
        <div>
          <b-spinner v-if="searchingUserInfo"></b-spinner>
          <span class="user-name" v-else>{{ userName }}</span>
        </div>
      </div>
      <div class="logout my-5 py-1 text-center" @click="logOut">
        Logout
      </div>
      <div class="text-center">
        <b-spinner v-if="searchingUserReadingsSummary"></b-spinner>
        <div
          class="text-left"
          v-else
          v-for="(reading, index) in readings"
          :key="index"
        >
          <reading-summary :reading="reading"></reading-summary>
        </div>
      </div>

      <Menu :activeNav="2" />
    </div>
  </b-overlay>
</template>

<script>
import { Vue } from "vue-property-decorator";
import Menu from "@/components/Menu";
import SvgIcon from "@/components/SvgIcon";
import ReadingSummary from "@/components/ReadingSummary";

import readingService from "@/services/readingService";
import loginService from "@/services/LoginService";
import userService from "@/services/userService";

import toastHelper from "@/helpers/toast";

export default Vue.extend({
  components: {
    Menu,
    ReadingSummary,
    SvgIcon,
  },
  data() {
    return {
      showOverlay: false,
      readings: [],
      userName: "",
      searchingUserInfo: true,
      searchingUserReadingsSummary: true,
    };
  },
  mounted() {
    readingService
      .readingSummary()
      .then((res) => {
        this.readings = res.data;
      })
      .catch(() => {
        toastHelper.errorToast(
          "Error",
          "An error occurred when getting the user reading summary"
        );
      })
      .finally(() => {
        this.searchingUserReadingsSummary = false;
      });

    userService
      .myInfo()
      .then((res) => {
        this.userName = res.data;
      })
      .catch(() => {
        toastHelper.errorToast(
          "Error",
          "An error occurred when getting the user information"
        );
      })
      .finally(() => {
        this.searchingUserInfo = false;
      });
  },
  methods: {
    logOut() {
      this.showOverlay = true;
      loginService
        .logOut(loginService.getAccessToken())
        .then(() => {
          sessionStorage.clear();
          location.reload();
        })
        .catch(() => {
          toastHelper.errorToast(
            "Error",
            "An error occurred during the logout"
          );
        });
    },
  },
});
</script>
<style lang="scss" scoped>
@import "../styles/scss/variables";
.overlay {
  font-size: 1.6rem;
}
.profile {
  background-color: $profile-background;
  min-height: 100vh;
  .personal-details {
    .user-name {
      font-size: 1.8rem;
    }
    .avatar {
      font-size: 5.4rem;
    }
  }
  .logout {
    border: 1px solid $red;
    font-size: 1.4rem;
    border-radius: 5px;
    color: $red;
  }
}
</style>
