import http from "../http";
import ReadingModel from "@/models/readingModel";

const ENDPOINT = "assignments";

const save = (reading: ReadingModel) => http.put(`${ENDPOINT}`, reading, { cache: false });
const readingSummary = () => http.get(`${ENDPOINT}/summary`, { forceUpdate:true });

export default {
  save,
  readingSummary,
};
