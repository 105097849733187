import Vue from "vue";

const vue = new Vue({});

const defaultToast = (title: string, body: string, variant: string) => {
  vue.$bvToast.toast(body, {
    title: title,
    variant: variant,
    solid: true,
  });
};

const errorToast = (title: string, body: string) => {
  defaultToast(title, body, "danger");
};

const successToast = (title: string, body: string) => {
  defaultToast(title, body, "success");
};

export default {
  defaultToast,
  errorToast,
  successToast,
};
